export const environment: any = {
  "production": true,
  "API": "https://api.rushmydocs.com/api/",
  "client_url": "https://my.govworks.com",
  "visas": false,
  "header": {
    "logo": "https://govworks-email-assets.s3.amazonaws.com/RushMyDocs/logo-rmd%402x.png"
  },
  "support": {
    "phone": "8002307142"
  },
  "login": {
    "enabled": false,
    "logo": "https://govworks-email-assets.s3.amazonaws.com/RushMyDocs/logo-rmd%402x.png"
  },
  "fedex_logo": true,
  "legal_links": {
    "terms": "https://www.rushmydocs.com/terms-of-use",
    "privacy": "https://www.rushmydocs.com/privacy-policy",
    "refund": "https://www.rushmydocs.com/refund-policy"
  },
  "source": {
    "domain": "rushmydocs",
    "main_website": "https://www.rushmydocs.com",
    "promo_code": false,
    "name": "RushMyDocs"
  },
  "footer": {
    "logo": "https://govworks-email-assets.s3.amazonaws.com/RushMyDocs/logo-rmd-light%402x.png",
    "logo_link": "/",
    "copyright": "RushMyDocs.com"
  },
  "landing_pages": {
    "passport": {
      "enabled": false
    },
    "visa": {
      "enabled": false
    },
    "root_type": "passport"
  },
  "create_lead": true, 
  "summary_cta_icon": true,
  "livechat": {
    "enabled": false,
    "license_id": ""
  },
  "tags": {
    "adwords": "AW-667976494",
    "gtag_conv": "Xc31CJeVhcMBEK6Gwr4C",
    "ga4": ["G-DMLKT5SNGG"]
  },
  "seals": {},
  "locations_page": {
    "enabled": false
  }
};